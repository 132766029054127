import { useState, useEffect } from "react";
import { useWindowSize } from "react-use";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import WeatherCard from "examples/Cards/WeatherCard";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import ThinBarChart from "examples/Charts/BarCharts/ThinBarChart";
import ControllerCard from "examples/Cards/ControllerCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// SmartHome dashboard components
import Cameras from "layouts/dashboards/work-in-progress/components/Cameras";
import TemperatureSlider from "layouts/dashboards/smart-home/components/TemperatureSlider";

// Data
import reportsDoughnutChartData from "layouts/dashboards/smart-home/data/reportsDoughnutChartData";
import thinBarChartData from "layouts/dashboards/smart-home/data/thinBarChartData";
import controllerCardIcons from "layouts/dashboards/smart-home/data/controllerCardIcons";

// Images
import iconSunCloud from "assets/images/small-logos/icon-sun-cloud.png";
import work from "assets/images/new_features.jpg";
import mobileWork from "assets/images/new_features_mobile.jpg";


function SmartHome() {
  const [temperature, setTemperature] = useState(21);
  const {
    humidityIconLight,
    temperatureIconLight,
    airConditionerIconLight,
    lightsIconLight,
    wifiIconLight,
    humidityIconDark,
    airConditionerIconDark,
    lightsIconDark,
    wifiIconDark,
  } = controllerCardIcons;

  const { width } = useWindowSize();
  const isMobile = width <= 600;



  return (
    <DashboardLayout sx={{  display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <DashboardNavbar />
      <SoftBox component="main" sx={{ flexGrow: 1, pt: 3, pb: 3 }}>
        <SoftBox mb={3}>
          <Grid>
            <Grid item xs={12} xl={12}>
              <Card >
                <SoftBox p={2} mt={1} mb={0} ml={0} width="100%" height="26.25rem">
                  <img src={isMobile ? mobileWork : work} alt="work in progress" style={{ borderRadius: "lg", width: "100%", height: "auto", objectFit: "cover" }} />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={0}>
          <Grid container spacing={3}>
          </Grid>
        </SoftBox>
      </SoftBox>
      <SoftBox sx={{ position: 'relative',
          textAlign: 'center',
          width: '100%', 
          py: 2,
          }}
           >
        <SoftBox my={6} width="100%" >
          <Divider />
        </SoftBox>
        <Footer />
      </SoftBox>      
    </DashboardLayout>
  );
}

export default SmartHome
