import React from 'react';
import Welcome from "layouts/dashboards/welcome";
import Mail from "layouts/pages/projects/mail/upload";
import Documents from "layouts/pages/projects/mail/documents";
import Prefix from "layouts/pages/projects/prefix";
import Insurance from "layouts/pages/projects/insurance";
import Progress from "layouts/dashboards/work-in-progress";
import Patient from "layouts/dashboards/patient-reimbursement";
import Notifications from "layouts/pages/notifications";
//Authentication
import SignInBasic from "layouts/authentication/sign-in";
import SignUpCover from "layouts/authentication/sign-up";
import Reports from "layouts/pages/users/reports";
import FirstLogin from "layouts/authentication/first-login";
import ResetBasic from "layouts/authentication/changepassword/basic";
import EmailVerification from "layouts/authentication/emailverification";
import ForgotPassword from "layouts/authentication/forgot-password/forgotpassword";
import ResetPassword from 'layouts/authentication/forgot-password/newpassword';
//MFA
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
//Error
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";
//Icons
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";


const routes = [
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Welcome",
    key: "welcome",
    protected: true,
    icon: <CustomerSupport size="12px" />,
    collapse: [
   
      {
        name: "Welcome",
        key: "welcome",
        route: "/welcome",
        component: <Welcome />,
        protected: true,
      },
    ],
    },
  {
    type: "collapse",
    name: "Rates",
    key: "rates",
    icon: <SpaceShip size="12px" />,
    collapse: [
   
      {
        name: "Prefix search",
        key: "prefix",
        route: "/rates/prefix",
        component: <Prefix />,
        protected: true,
      },
      {
        name: "Insurance search",
        key: "insurance",
        route: "/rates/insurance",
        component: <Insurance />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Office size="12px" />,
    collapse: [
      {
        name: "Patient Reimbursement",
        key: "patient-reimnbursement",
        route: "/dashboards/patient-reimnbursement",
        component: <Patient />,
        protected: true,
      },
      {
        name: "Insurance Reimbursement",
        key: "insurance-reimnbursement",
        route: "/dashboards/insurance-reimnbursement",
        component: <Progress />,
        protected: true,
      },
      {
        name: "EIVs",
        key: "eivs",
        route: "/dashboards/eivs",
        component: <Progress />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "User Management",
    key: "authentication",
    icon: <SettingsIcon size="12px" />,
    collapse: [
      
      /**{
        name: "User profile",
        key: "user-profile",
        route: "/authentication/user-profile",
        component: <ProfileOverview />,
      },*/
      {
        name: "User list",
        key: "user-list",
        route: "/user-list",
        component: <Reports />,
        requireadmin: true
      },
      {
        name: "User creation",
        key: "user-creation",
        route: "/authentication/user-creation",
        component: <SignUpCover />,
        requireadmin: true
      },
      {
        name: "Change password",
        key: "change-password",
        route: "/authentication/change-password",
        component: <ResetBasic />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Mail",
    key: "mail",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Upload documents",
        key: "upload-documents",
        route: "/mail/upload-documents",
        component: <Mail />,
        protected: true,
      },
      {
        name: "Documents",
        key: "list-documents",
        route: "/mail/list-documents",
        component: <Documents />,
        protected: true,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  //Hidden routes-------------------------------------------------------------------------
  {
    name: "Notifications",
    key: "notification",
    collapse: [
      {
        name: "notification",
        key: "notification",
        route: "/notification",
        component: <Notifications />,
      },
    ],
  },
  {
    name: "Sign In",
    key: "sign-in",
    collapse: [
      {
        name: "sign-in",
        key: "sign-in",
        route: "/authentication/sign-in",
        component: <SignInBasic />,
      },
    ],
  },
  {
    name: "email-verification",
    key: "email-verification",
    route: "/authentication/email-verification",
    component: <EmailVerification />,
  },
  {
    name: "First Login",
    key: "cover",
    route: "/authentication/first-login",
    component: <FirstLogin />,
  },
 
  {
    name: "Basic",
    key: "basic",
    route: "/authentication/verification/basic",
    component: <VerificationBasic />,
  },
  {
    name: "Cover",
    key: "cover",
    route: "/authentication/verification/cover",
    component: <VerificationCover />,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/verification/illustration",
    component: <VerificationIllustration />,
  },
  {
    name: "Error 404",
    key: "error-404",
    route: "/authentication/error/404",
    component: <Error404 />,
  },
  {
    name: "Error 500",
    key: "error-500",
    route: "/authentication/error/500",
    component: <Error500 />,
  },
  {
    name: "forgot-password",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    component: <ForgotPassword />,
  },
  {
    name: "ConfirmationCode",
    key: "ConfirmationCode",
    route: "/reset-password",
    component: <ResetPassword />,
  },
]
export default routes;
