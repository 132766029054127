import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";



// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved11 from "assets/images/curved-images/curved11.jpg";

import AuthApi from "../../../../api/auth";

function ForgotPassword() {
  const [agreement, setAgreement] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    verificationCode: '', 
    newPassword: '',
    repeatNewPassword: ''
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSetAgreement = () => setAgreement(!agreement);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setError("");
    if (formData.newPassword !== formData.repeatNewPassword) {
      setError("New passwords do not match");
      return;
    }
    AuthApi.ChangeForgottenPassword(formData).then(response => {
      setLoading(false);
      if (response) {
        navigate("/authentication/sign-in");
      } else {
        setError("Unable to reset password");
      }
    }).catch(error => {
      setLoading(false);
      setError("There has been an error.");
      console.error("Error during verification:", error);
    });
  };

  return (
    <CoverLayout
      title="Forgot Password"
      image={curved11}
      top={25}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email" value={formData?.email} name="email" onChange={handleChange} />
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Verification Code
            </SoftTypography>
          </SoftBox>
          <SoftInput type="Verification Code" placeholder="Verification Code" value={formData?.verificationCode} name="verificationCode" onChange={handleChange} />
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25} position="relative">
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              New Password
            </SoftTypography>
          </SoftBox>
          <OutlinedInput
            type={showNewPassword ? "text" : "password"}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            fullWidth
            placeholder="New Password"
            style={{ paddingRight: 40 }}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowNewPassword(!showNewPassword)}
            edge="end"
            style={{
              position: 'absolute',
              right: 10,
              top: '70%',
              transform: 'translateY(-50%)'
            }}
          >
            {showNewPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25} position="relative">
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Repeat New Password
            </SoftTypography>
          </SoftBox>
          <OutlinedInput
            type={showRepeatNewPassword ? "text" : "password"}
            name="repeatNewPassword"
            value={formData.repeatNewPassword}
            onChange={handleChange}
            fullWidth
            placeholder="Repeat New Password"
            style={{ paddingRight: 40 }}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
            edge="end"
            style={{
              position: 'absolute',
              right: 10,
              top: '70%',
              transform: 'translateY(-50%)'
            }}
          >
            {showRepeatNewPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </SoftBox>
        
        
        <SoftBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SoftBox>
        <SoftBox mt={4} mb={1} textAlign="center">
          <SoftButton variant="gradient" color="info" onClick={handleSubmit} fullWidth disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Reset password'}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ForgotPassword;
